import { defineStore } from 'pinia'
import type { DepartmentSearchItem } from '~/types/department'
import type { User } from '~/types/user'
import type { AnniversarySearchQuery } from '~/types/anniversary'
import type { MessageSearchQuery } from '~/types/messageSearchQuery'
import type { MemberSearchQuery } from '~/types/member'

type TemplateMessageModal = {
  isVisible: boolean
  message: string
}

type ModalState = {
  isVisible: boolean
  hasDetail: boolean
  memberCode: null | string
}

type MemberIndexModal = {
  create: ModalState
}

type State = {
  user: null | User
  ip: null | string
  templateMessageModal: TemplateMessageModal
  memberIndexModal: MemberIndexModal
  messageUnread: null | number
  newAnniversaryAnswer: null | number
  dashboardTutorialFinished: false | boolean
  memberSearch: null | MemberSearchQuery
  messageSearch: null | MessageSearchQuery
  anniversarySearch: null | AnniversarySearchQuery
  shouldAlertNoLicense: false | boolean
  expandedSideMenu: false | boolean
  departments: null | DepartmentSearchItem[]
}

const state: () => State = () => ({
  user: null,
  ip: null,
  templateMessageModal: {
    isVisible: false,
    type: 'success',
    message: '',
  },
  memberIndexModal: {
    create: {
      isVisible: false,
      hasDetail: false,
      memberCode: null,
    },
  },
  messageUnread: null,
  newAnniversaryAnswer: null,
  dashboardTutorialFinished: false,
  memberSearch: null,
  messageSearch: null,
  anniversarySearch: null,
  shouldAlertNoLicense: false,
  expandedSideMenu: false,
  departments: null,
})

export const useStore = defineStore('store', {
  state,
  actions: {
    setUser(user: null | User) {
      this.user = user
    },
    setCurrentIp(ip: null | string) {
      this.ip = ip
    },
    setMessageTemplateModal(templateMessageModal: TemplateMessageModal) {
      this.templateMessageModal = templateMessageModal
    },
    setMemberIndexCreateModal(modalState: ModalState) {
      this.memberIndexModal.create = modalState
    },
    setMessageUnread(messageUnread: number) {
      this.messageUnread = messageUnread
    },
    setNewAnniversaryAnswer(newAnniversaryAnswer: number) {
      this.newAnniversaryAnswer = newAnniversaryAnswer
    },
    setDashboardTutorialFinished(dashboardTutorialFinished: boolean) {
      this.dashboardTutorialFinished = dashboardTutorialFinished
    },
    setMemberSearch(memberSearch: MemberSearchQuery | null) {
      this.memberSearch = memberSearch
    },
    setMessageSearch(messageSearchQuery: MessageSearchQuery) {
      this.messageSearch = messageSearchQuery
    },
    setAnniversarySearch(anniversarySearch: AnniversarySearchQuery | null) {
      this.anniversarySearch = anniversarySearch
    },
    setShouldAlertNoLicense(shouldAlertNoLicense: boolean) {
      this.shouldAlertNoLicense = shouldAlertNoLicense
    },
    setExpandedSideMenu(expandedSideMenu: boolean) {
      this.expandedSideMenu = expandedSideMenu
    },
    setDepartments(departments: DepartmentSearchItem[] | null) {
      this.departments = departments
    },
  },
  getters: {
    getUser(state: State) {
      return state.user
    },
    getCurrentIp(state: State) {
      return state.ip
    },
    getMessageUnread(state: State) {
      return state.messageUnread
    },
    getNewAnniversaryAnswer(state: State) {
      return state.newAnniversaryAnswer
    },
    getDashboardTutorialFinishedl(state: State) {
      return state.dashboardTutorialFinished
    },
    getMemberSearch(state: State) {
      return state.memberSearch
    },
    getMessageSearch(state: State) {
      return state.messageSearch
    },
    getAnniversarySearch(state: State) {
      return state.anniversarySearch
    },
    getShouldAlertNoLicense(state: State) {
      return state.shouldAlertNoLicense
    },
    getExpandedSideMenu(state: State) {
      return state.expandedSideMenu
    },
    getDepartments(state: State) {
      return state.departments
    },
    getTemplateMessageModal(state: State) {
      return state.templateMessageModal
    },
    getMemberIndexCreateModal(state: State) {
      return state.memberIndexModal.create
    },
  },
})
